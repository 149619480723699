import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  DigitalMyBookingContainer,
  ProductItemImage,
} from './CartPage.components';
import { LeftIcon, OrderItems, numberFormat } from '../DigitalMenus.components';

const DigitalMyBooking = props => {
  const {
    calculateOrderPricing,
    menus,
    switchPage,
    tableNo,
    tax,
    total,
    bookingOrder,
  } = props;

  useEffect(() => {
    calculateOrderPricing();
  });

  return (
    <DigitalMyBookingContainer>
      <div className="header">
        <div
          style={{ position: 'absolute', left: 11 }}
          onClick={() => switchPage(1)}
        >
          <LeftIcon />
        </div>
        <span>My Order</span>
      </div>
      <div className="table-name">
        <span>{`Table Number - ${tableNo}`}</span>
      </div>
      <OrderItems>
        <div className="restaurant-items-title">Items</div>
        <div className="restaurant-items">
          {menus &&
            menus.map(item => {
              return (
                <div className="restaurant-item" key={item.menuItemCode}>
                  <div>
                    <ProductItemImage bg={item.imageURL} />
                  </div>
                  <div className="restaurant-items-count-name">
                    <div className="restaurant-items-name">{item.name}</div>
                    <div className="restaurant-items-count">{`X ${item.number}`}</div>
                  </div>
                  <div className="restaurant-items-price">
                    {`Rp${numberFormat(item.price * item.number, 0, '.', '.')}`}
                  </div>
                </div>
              );
            })}
        </div>
        <div />
      </OrderItems>
      <div style={{ height: 120, width: '100vw' }} />
      <div className="confirm-button-container">
        <div className="bottom-tax">
          <span className="left">Food Tax</span>
          <span className="right">
            {`Rp ${numberFormat(tax, 0, '.', '.')}`}
          </span>
        </div>
        <div className="botton-total">
          <span className="left">Total Amount</span>
          <span className="right">
            {`Rp ${numberFormat(total, 0, '.', '.')}`}
          </span>
        </div>
        <div
          className={
            tableNo === '' ? 'confirm-button disabled' : 'confirm-button'
          }
          onClick={bookingOrder}
        >
          Confirm
        </div>
      </div>
    </DigitalMyBookingContainer>
  );
};

DigitalMyBooking.propTypes = {
  calculateOrderPricing: PropTypes.func.isRequired,
  menus: PropTypes.array.isRequired,
  switchPage: PropTypes.func.isRequired,
  tableNo: PropTypes.string.isRequired,
  tax: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  bookingOrder: PropTypes.func.isRequired,
};

export default DigitalMyBooking;
