export default function(e, t) {
  const n = e.imgLos || { _q: [], _iq: {} };
  const s = t.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = 'https://qraved-images.s3-ap-southeast-1.amazonaws.com/imgLosV1.min.js';
  s.onload = function() {
    if (e.imgLos.runQueuedFunctions) {
      e.imgLos.runQueuedFunctions();
    } else {
      console.log('[ImgLos] Error: could not load SDK');
    }
  };
  const r = t.getElementsByTagName('script')[0];
  r.parentNode.insertBefore(s, r);
  function o(e, t) {
    e.prototype[t] = function() {
      this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
      return this;
    };
  }
  const i = function() {
    this._q = [];
    return this;
  };
  const a = ['add', 'append', 'clearAll', 'prepend', 'set', 'setOnce', 'unset'];
  for (let c = 0; c < a.length; c++) {
    o(i, a[c]);
  }
  n.Identify = i;
  const u = function() {
    this._q = [];
    return this;
  };
  const l = [
    'setProductId',
    'setQuantity',
    'setPrice',
    'setRevenueType',
    'setEventProperties',
  ];
  for (let p = 0; p < l.length; p++) {
    o(u, l[p]);
  }
  n.Revenue = u;
  const g = [
    'init',
    'logEvent',
    'logRevenue',
    'setUserId',
    'setUserProperties',
    'setOptOut',
    'setVersionName',
    'setDomain',
    'setDeviceId',
    'setGlobalUserProperties',
    'identify',
    'clearUserProperties',
    'setGroup',
    'logRevenueV2',
    'regenerateDeviceId',
    'logEventWithTimestamp',
    'logEventWithGroups',
    'setSessionId',
  ];
  function v(e) {
    function t(t) {
      e[t] = function() {
        e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
      };
    }
    for (let n = 0; n < g.length; n++) {
      t(g[n]);
    }
  }
  v(n);
  n.getInstance = function(e) {
    e = (!e || e.length === 0 ? '$default_instance' : e).toLowerCase();
    if (!n._iq.hasOwnProperty(e)) {
      n._iq[e] = { _q: [] };
      v(n._iq[e]);
    }
    return n._iq[e];
  };
  e.imgLos = n;
}
