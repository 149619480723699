import {
  CHECK_QR_CODE,
  CHECK_QR_CODE_SUCCEED,
  CHECK_QR_CODE_ERROR,
} from './QRCode.constants';

export const checkQRCode = id => ({
  type: CHECK_QR_CODE,
  id,
});

export const checkQRCodeSuccess = payload => ({
  type: CHECK_QR_CODE_SUCCEED,
  payload,
});

export const setErrorMessage = error => ({
  type: CHECK_QR_CODE_ERROR,
  error,
});
