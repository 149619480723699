import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import QRCodePageContainer from './QRCode.components';
import EasyLoading from '../../components/Loading/EasyLoading';
import {
  LOADING,
  ASSET_TYPE_PROMO,
  openAppOrWeb,
  ASSET_TYPE_OTHER,
  ASSET_TYPE_QRO,
  ASSET_TYPE_MENU,
  ASSET_TYPES,
  DIGITAL_MENU,
  ASSET_TYPE_QOA,
  getRandomStrFromTime,
} from './QRCode.constants';
import Config from '../../config';
import userAgent from '../../utils/userAgent';
import { PV_SCAN_QR_CODE } from '../../constants/events';
import eventEmitter from '../../utils/EventEmitter';

const QRCode = props => {
  const { errorMessage, qrcode: qrCodeInfo, match, onCheckQRCode } = props;
  const {
    params: { id },
  } = match;
  useEffect(() => {
    if (!qrCodeInfo) {
      onCheckQRCode(id);
    }
    if (qrCodeInfo) {
      if (!qrCodeInfo.target_type) {
        window.location.href = 'https://www.qraved.com/404';
        return false;
      }
      const targetType = ASSET_TYPES.filter(
        item => item.id === qrCodeInfo.target_type,
      );
      const handles = async () => {
        await eventEmitter.emit(PV_SCAN_QR_CODE, {
          ...qrCodeInfo,
          QR_code_ID: id,
          QR_code_link: window.location.href,
          target_type: targetType[0] ? targetType[0].name : '',
        });
        if (qrCodeInfo.target_type === ASSET_TYPE_QOA) {
          window.location.href = qrCodeInfo.web_link;
          return false;
        }
        if (qrCodeInfo.target_type === ASSET_TYPE_OTHER) {
          window.location.href = qrCodeInfo.web_link;
          return false;
        }
        if (qrCodeInfo.target_type === 312) {
          window.location.href = qrCodeInfo.redirect_link;
          return false;
        }
        if (qrCodeInfo.target_type === 321) {
          window.location.href = qrCodeInfo.redirect_link;
          return false;
        }

        if (qrCodeInfo.target_type === DIGITAL_MENU) {
          const random = getRandomStrFromTime();
          if (qrCodeInfo.web_link && qrCodeInfo.web_link.includes('?')) {
            window.location.href = `${qrCodeInfo.web_link}&k=${random}`;
          } else if (qrCodeInfo.web_link) {
            window.location.href = `${qrCodeInfo.web_link}?k=${random}`;
          }
          return false;
        }

        if (qrCodeInfo.target_type === ASSET_TYPE_PROMO) {
          if (userAgent.getOS() === 'iOS') {
            openAppOrWeb(qrCodeInfo.app_link, Config.APP_STORE);
            return false;
          }
          openAppOrWeb(qrCodeInfo.app_link, Config.PLAY_STORE);
          return false;
        }
        if (qrCodeInfo.target_type === ASSET_TYPE_QRO) {
          window.location.href = `${qrCodeInfo.web_link}&id=${id}`;
          return false;
        }
        const weblink =
          qrCodeInfo.target_type === ASSET_TYPE_MENU
            ? `${qrCodeInfo.web_link}?from_qr_code=1#menus-restaurant`
            : `${qrCodeInfo.web_link}?from_qr_code=1`;
        openAppOrWeb(qrCodeInfo.app_link, weblink);
        window.location.href = qrCodeInfo.redirect_link || qrCodeInfo.web_link;
        return false;
      };
      handles();
    }
    return () => {};
  }, [qrCodeInfo, errorMessage, match, onCheckQRCode, id]);

  return (
    <QRCodePageContainer>
      {!errorMessage ? (
        <div className="loading">
          <EasyLoading />
          <span className="loading-text">{LOADING}</span>
        </div>
      ) : (
        <div className="error">{errorMessage}</div>
      )}
    </QRCodePageContainer>
  );
};

QRCode.propTypes = {
  match: PropTypes.object,
  errorMessage: PropTypes.string,
  onCheckQRCode: PropTypes.any,
  qrcode: PropTypes.object,
};

QRCode.defaultProps = {
  match: {},
  errorMessage: '',
  qrcode: null,
  onCheckQRCode: () => {},
};

export default QRCode;
