export const REDUCER_KEY = 'spring-qraved-short-url';
export const SAGA_KEY = 'spring-qraved-short-url-saga';
export const LOADING = 'Loading...';

export const PORTAL_TYPES = {
  wa: {
    rt: 300,
    ch_b: 301,
  },
  lt: {
    rt: 310,
    ch_b: 311,
  },
};
export const SOURCEWHATSAPP = [300, 301];
export const SOURCELINKTREE = [310, 311];

// reducer
export const CHECK_SHORT_URL_DETAIL =
  'spring-qraved/short/url/CHECK_SHORT_URL_DETAIL';
export const SET_SHORT_URL_SUCCEED =
  'spring-qraved/short/url/SET_SHORT_URL_SUCCEED';
export const SET_SHORT_URL_ERROR =
  'spring-qraved/short/url/SET_SHORT_URL_ERROR';

export const ACTION_VIEW_SHORT_URL_PAGE = 'action_view_short_url_page';
export const ACTION_VIEW_SHORT_URL_PAGE_EVENT_NAME =
  'RC - QDO Redirecting Page';
