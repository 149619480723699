import { createSelector } from 'reselect';

import { REDUCER_KEY } from './DigitalMenus.constants';
import { initialState } from './DigitalMenus.reducer';

const digitalMenusPage = state => state[REDUCER_KEY] || initialState;

const makeSelectStoreAndMenu = () =>
  createSelector(digitalMenusPage, digitalMenusState => digitalMenusState.data);

const makeSelectCurrentPage = () =>
  createSelector(
    digitalMenusPage,
    digitalMenusState => digitalMenusState.currentPage,
  );

const makeSelectSelectedFood = () =>
  createSelector(
    digitalMenusPage,
    digitalMenusState => digitalMenusState.selectedFood,
  );

const makeSelectOutside = () =>
  createSelector(
    digitalMenusPage,
    digitalMenusState => digitalMenusState.outside,
  );

const makeSelectSelectedFoodNumber = () =>
  createSelector(
    digitalMenusPage,
    digitalMenusState => digitalMenusState.selectedFoodNumber,
  );

const makeSelectTotal = () =>
  createSelector(
    digitalMenusPage,
    digitalMenusState => digitalMenusState.total,
  );

const makeSelectTax = () =>
  createSelector(digitalMenusPage, digitalMenusState => digitalMenusState.tax);

const makeSelectPage2loading = () =>
  createSelector(
    digitalMenusPage,
    digitalMenusState => digitalMenusState.page2loading,
  );

const makeSelectPage1loading = () =>
  createSelector(
    digitalMenusPage,
    digitalMenusState => digitalMenusState.page1loading,
  );

const makeSelectMinCharge = () =>
  createSelector(
    digitalMenusPage,
    digitalMenusState => digitalMenusState.minCharge,
  );

const makeSelectName = () =>
  createSelector(digitalMenusPage, digitalMenusState => digitalMenusState.name);

export {
  makeSelectStoreAndMenu,
  makeSelectCurrentPage,
  makeSelectSelectedFood,
  makeSelectOutside,
  makeSelectSelectedFoodNumber,
  makeSelectTotal,
  makeSelectTax,
  makeSelectPage2loading,
  makeSelectPage1loading,
  makeSelectMinCharge,
  makeSelectName,
};
