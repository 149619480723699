import { memoize } from 'lodash';

export default {
  stamp: {
    get: memoize(
      (userId, token) => `/client/user/verify?user_id=${userId}&token=${token}`,
    ),
  },
  qrcode: {
    get: memoize(id => `/id/home/client/general_qr_code?id=${id}`),
  },
  digitalMenu: {
    getStoreAndMenu: memoize(
      outletCode => `/storeMenu?outletCode=${outletCode}`,
    ),
    orderdetails: memoize(requestID => `orderdetails?requestID=${requestID}`),
    consultOrder: 'consultorder',
    calculateorder: 'calculateorder',
  },
  portal: {
    get: memoize(
      (portalType, seoKeyword) =>
        `/id/home/client/general_qr_code/${portalType}/${seoKeyword}`,
    ),
  },

  shortUrl: {
    get: memoize(
      shortUrlId => `/id/home/client/asset_link?link_id=${shortUrlId}`,
    ),
  },
};
