import { createSelector } from 'reselect';

import { REDUCER_KEY } from './QRCode.constants';
import { initialState } from './QRCode.reducer';

const selectQRCodePage = state => state[REDUCER_KEY] || initialState;

const makeSelectQRCode = () =>
  createSelector(selectQRCodePage, qRCodePageState => qRCodePageState.qrcode);

const makeSelectErrorMessage = () =>
  createSelector(
    selectQRCodePage,
    qRCodePageState => qRCodePageState.errorMessage,
  );

export { makeSelectQRCode, makeSelectErrorMessage };
