import ApiConstants from '../../../constants/ApiConstants';
import { RequestClientClass } from '../../../utils/requestClient';
import Config from '../../../config';

const requestClient = () => new RequestClientClass(Config.QRAVED_SERVER);

const doCheckPortal = async payload => {
  const { portalType, seoKeyword } = payload;
  const uri = ApiConstants.portal.get(portalType, seoKeyword);
  const response = await requestClient()
    .setUri(uri)
    .doGet();
  return response;
};

export default {
  doCheckPortal,
};
