const Config = {
  QRAVED_SERVICE_API_KEY: 'JT4QQCJcB7qbwk',
  QRAVED_SERVER: 'https://apigw.qraved.com',
  DIGITAL_MENU_SERVER: 'https://mth.qraved.com/api/qro',
  ECHOSS_LICENSE_ID: 'p75d28cb69df040c8afe409939a9de9a3',
  PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.qraved.app',
  APP_STORE: 'https://itunes.apple.com/app/apple-store/id731842943?pt=5672800',
  LOS_API_KEY: '4kjl9us0o98yr6ka7ykvy9wnwc4ue1kd',
  QRAVED_WEB_LIKE: 'https://www.qraved.com/',
  CDN: 'https://node-img.qraved.com/spring',
  GA_ID: 'UA-42073500-1',
};

export default Config;
