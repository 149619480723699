import styled from 'styled-components';

export const ShortUrlPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .loading-text {
      font-size: 14px;
      font-weight: bold;
      margin-top: 20px;
      margin-left: 15px;
    }
  }

  .error {
  }
`;
