import styled from 'styled-components';

export const CheckOutPageContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background: #eaeaea;
  .header {
    position: fixed;
    flex-shrink: 0;
    width: 100%;
    height: 64px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.425px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }
  .table-name {
    margin-top: 64px;
    flex-shrink: 0;
    width: 100%;
    height: 105px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    color: #333333;
    span {
      margin-left: 10px;
    }
  }
  .empty-message {
    margin-top: 72px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: right;
    color: #979797;
  }
  .empty-button-container {
    padding: 0 43px;
    margin-top: 155px;
    .empty-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #d20000;
      height: 45px;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
    }
  }
  .confirm-button-container {
    position: fixed;
    width: 100vw;
    height: 174px;
    bottom: 0;
    background: #fff;
    .disabled {
      pointer-events: none;
      background: #999;
    }
    .confirm-button {
      display: flex;
      background: #d20000;
      height: 45px;
      justify-content: center;
      align-items: center;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }

    .bottom-tax {
      display: flex;
      padding: 10px 15px 0;
      justify-content: space-between;
      height: 32px;
      background: #fff;
      .left {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
      }
      right {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
      }
    }

    .botton-total {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      height: 150px;
      .left {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #333333;
      }
      .right {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        color: #333333;
        line-height: 120px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
`;
