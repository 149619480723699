import { put, call, takeLatest } from 'redux-saga/effects';

import { CHECK_QR_CODE } from './QRCode.constants';
import { checkQRCodeSuccess, setErrorMessage } from './QRCode.actions';
import QRCodeService from '../../service/api/QRCode';

export function* checkQRCode(action) {
  try {
    const { id } = action;
    const response = yield call(QRCodeService.doCheckQRCode, { id });
    if (response) {
      yield put(checkQRCodeSuccess(response));
    } else {
      yield put(setErrorMessage('error'));
    }
  } catch (e) {
    yield put(setErrorMessage('error'));
  }
}

export default function* signInSaga() {
  yield takeLatest(CHECK_QR_CODE, checkQRCode);
}
