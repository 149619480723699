import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import eventEmitter from '../../utils/EventEmitter';
import { PortalPageContainer } from './Portal.components';
import EasyLoading from '../../components/Loading/EasyLoading';
import { PORTAL_TYPES, ACTION_VIEW_PORTAL_PAGE } from './Portal.constants';

const Portal = props => {
  const {
    match,
    redirectLink,
    sourceType,
    sourceName,
    errorMessage,
    onCheckPortal,
    onCheckPortalFailed,
  } = props;

  const {
    params: { thirdType, assetType, seoKeyword },
  } = match;

  useEffect(() => {
    if (redirectLink) {
      // Case 1: Already Obtained `redirectLink`, Redirecting
      eventEmitter.emit(ACTION_VIEW_PORTAL_PAGE, {
        sourceType,
        sourceName,
        redirectLink,
        seoKeyword,
      });
      window.location.href = redirectLink;
    } else if (
      !redirectLink &&
      PORTAL_TYPES[thirdType] !== undefined &&
      PORTAL_TYPES[thirdType][assetType] > 0 &&
      seoKeyword
    ) {
      // Case 2: Fetch `redirectLink`
      onCheckPortal(PORTAL_TYPES[thirdType][assetType], seoKeyword);
    } else {
      // Case 3: Else, give error page
      onCheckPortalFailed('Invalid Link!');
    }
  }, [
    redirectLink,
    assetType,
    onCheckPortal,
    onCheckPortalFailed,
    seoKeyword,
    sourceName,
    thirdType,
    sourceType,
  ]);

  return (
    <PortalPageContainer>
      {!errorMessage ? (
        <EasyLoading />
      ) : (
        <div className="error">{errorMessage}</div>
      )}
    </PortalPageContainer>
  );
};

Portal.propTypes = {
  match: PropTypes.object,
  redirectLink: PropTypes.string,
  errorMessage: PropTypes.string,
  sourceType: PropTypes.string,
  sourceName: PropTypes.string,
  onCheckPortal: PropTypes.func,
  onCheckPortalFailed: PropTypes.func,
};

Portal.defaultProps = {
  match: {},
  redirectLink: '',
  errorMessage: '',
  sourceType: '',
  sourceName: '',
  onCheckPortal: () => {},
  onCheckPortalFailed: () => {},
};

export default Portal;
