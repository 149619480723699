export const REDUCER_KEY = 'digital_menu';
export const SAGA_KEY = 'digital_menu';
export const SELECT_MENU_PAGE = 1;

export const SET_ERROR_MESSAGE = 'spring-qraved/digital_menu/SET_ERROR_MESSAGE';
export const GET_MENU_STORE = 'spring-qraved/digital_menu/GET_MENU_STORE';
export const GET_MENU_STORE_SUCCEED =
  'spring-qraved/digital_menu/GET_MENU_STORE_SUCCEED';
export const GET_MY_ORDER = 'spring-qraved/digital_menu/GET_MY_ORDER';
export const GET_MY_ORDER_SUCCEED =
  'spring-qraved/digital_menu/GET_MY_ORDER_SUCCEED';
export const CHECK_ORDER_PRICE = 'spring-qraved/digital_menu/CHECK_ORDER_PRICE';
export const CHECK_ORDER_PRICE_SUCCEED =
  'spring-qraved/digital_menu/CHECK_ORDER_PRICE_SUCCEED';
export const SUBMIT_ORDER = 'spring-qraved/digital_menu/SUBMIT_ORDER';
export const SUBMIT_ORDER_SUCCEED =
  'spring-qraved/digital_menu/SUBMIT_ORDER_SUCCEED';
export const SWITCH_PAGE = 'spring-qraved/digital_menu/SWITCH_PAGE';
export const CHANGE_SELECTED_FOOD =
  'spring-qraved/digital_menu/CHANGE_SELECTED_FOOD';
export const CLEAR_CART = 'spring-qraved/digital_menu/CLEAR_CART';
