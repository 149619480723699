import {
  CHECK_PORTAL,
  CHECK_PORTAL_SUCCEED,
  CHECK_PORTAL_ERROR,
} from './Portal.constants';

export const checkPortal = (portalType, seoKeyword) => ({
  type: CHECK_PORTAL,
  portalType,
  seoKeyword,
});

export const checkPortalSucceed = payload => ({
  type: CHECK_PORTAL_SUCCEED,
  payload,
});

export const checkPortalError = errorMessage => ({
  type: CHECK_PORTAL_ERROR,
  errorMessage,
});
