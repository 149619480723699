import styled from 'styled-components';

export const DigitalMyBookingContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background: #eaeaea;
  .header {
    position: fixed;
    flex-shrink: 0;
    width: 100%;
    height: 64px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.425px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }
  .table-name {
    flex-shrink: 0;
    width: 100%;
    height: 47px;
    background: #ffffff;
    display: flex;
    align-items: center;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    margin-top: 64px;
    span {
      margin-left: 10px;
    }
  }
  .empty-message {
    margin-top: 72px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: right;
    color: #979797;
  }
  .empty-button-container {
    padding: 0 43px;
    margin-top: 155px;
    .empty-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #d20000;
      height: 45px;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
    }
  }
  .confirm-button-container {
    position: fixed;
    width: 100vw;
    bottom: 0;
    .disabled {
      pointer-events: none;
      background: #999;
    }
    .confirm-button {
      display: flex;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f15c4f;
        background: #ffffff;
        height: 45px;
        width: 50vw;
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #d20000;
        color: #ffffff;
        height: 45px;
        width: 50vw;
      }
    }

    .bottom-tax {
      display: flex;
      padding: 10px 15px 0;
      justify-content: space-between;
      height: 32px;
      background: #fff;
      .left {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
      }
      right {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
      }
    }

    .botton-total {
      display: flex;
      padding: 0 15px;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      background: #eaeaea;
      .left {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
      }
      right {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
      }
    }
  }
`;

export const ProductItemImage = styled.div`
  background: url(${props => props.bg}) no-repeat center;
  background-size: cover;
  height: 55px;
  width: 55px;
`;
