import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import eventEmitter from '../../utils/EventEmitter';
import { ShortUrlPageContainer } from './ShortUrl.components';
import { ACTION_VIEW_SHORT_URL_PAGE } from './ShortUrl.constants';
import EasyLoading from '../../components/Loading/EasyLoading';
const ShortUrl = props => {
  const {
    redirectLink,
    onGetShortUrlDetail,
    onGetShortUrlDetailFailed,
    restaurantId,
    restaurantName,
    utmSource,
    brandId,
    losEvent,
    sourceTypeName,
    match,
  } = props;
  useEffect(() => {
    const {
      params: { shortUrlId },
    } = match;
    const fetchData = async () => {
      if (redirectLink) {
        await eventEmitter.emit(ACTION_VIEW_SHORT_URL_PAGE, {
          sourceTypeName,
          losEvent,
          redirectLink,
          restaurantId,
          brandId,
          restaurantName: restaurantName || '',
          UTMSource: utmSource || '',
        });
        window.location.href = redirectLink;
      } else if (!redirectLink && shortUrlId) {
        onGetShortUrlDetail(shortUrlId);
      } else {
        onGetShortUrlDetailFailed('Invalid Link!');
      }
    };
    fetchData();
  }, [
    redirectLink,
    onGetShortUrlDetail,
    onGetShortUrlDetailFailed,
    restaurantId,
    restaurantName,
    utmSource,
    brandId,
    losEvent,
    sourceTypeName,
    match,
  ]);
  const { errorMessage } = props;
  return (
    <ShortUrlPageContainer>
      {!errorMessage ? (
        <EasyLoading />
      ) : (
        <div className="error">{errorMessage}</div>
      )}
    </ShortUrlPageContainer>
  );
};

ShortUrl.propTypes = {
  match: PropTypes.object,
  redirectLink: PropTypes.string,
  errorMessage: PropTypes.string,
  sourceTypeName: PropTypes.string,
  losEvent: PropTypes.object,
  onGetShortUrlDetail: PropTypes.func,
  onGetShortUrlDetailFailed: PropTypes.func,
  restaurantId: PropTypes.string,
  restaurantName: PropTypes.string,
  utmSource: PropTypes.string,
  brandId: PropTypes.string,
};
ShortUrl.defaultProps = {
  match: {},
  redirectLink: '',
  errorMessage: '',
  sourceTypeName: '',
  losEvent: {},
  onGetShortUrlDetail: () => {},
  onGetShortUrlDetailFailed: () => {},
  restaurantId: '',
  restaurantName: '',
  utmSource: '',
  brandId: '',
};

export default ShortUrl;
