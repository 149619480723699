import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CheckOutPageContainer } from './CheckOutPage.components';
import {
  LeftIcon,
  OrderItems,
  numberFormat,
  ProductItemImage,
} from '../DigitalMenus.components';
import Loading from '../../../components/Loading';
import DigitalMenusService from '../../../service/api/DigitalMenus';

class CheckOutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderLines: [],
      totalRestaurantTax: 0,
      totalSales: 0,
      loading: true,
    };
  }

  async componentDidMount() {
    const { outletCode, tableNo } = this.props;
    const response = await DigitalMenusService.doGetMyOrder({
      requestID: `${outletCode}-${tableNo}`,
    });

    if (response.code === 200) {
      const { orderCalculation, orderLines } = response.data;
      const totalRestaurantTax = orderCalculation.find(
        item => item.key === 'totalRestaurantTax',
      ).value;
      const totalSales = orderCalculation.find(
        item => item.key === 'totalSales',
      ).value;
      this.setState({
        orderLines,
        totalRestaurantTax,
        totalSales,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { orderLines, totalRestaurantTax, totalSales, loading } = this.state;
    const { tableNo, switchPage } = this.props;
    return (
      <CheckOutPageContainer>
        <div className="header">
          <div
            style={{ position: 'absolute', left: 11 }}
            onClick={() => switchPage(3)}
          >
            <LeftIcon />
          </div>
          <span>Show to Waiter</span>
        </div>
        <div className="table-name">
          <span>{`Table Number - ${tableNo}`}</span>
        </div>
        {loading ? (
          <Loading mt={250} />
        ) : (
          <div className="">
            <OrderItems>
              <div className="restaurant-items-title">Items</div>
              <div className="restaurant-items">
                {orderLines &&
                  orderLines.map(item => {
                    return (
                      <div className="restaurant-item" key={item.menuItemCode}>
                        <div>
                          <ProductItemImage bg={item.imageURL} />
                        </div>
                        <div className="restaurant-items-count-name">
                          <div className="restaurant-items-name">
                            {item.name}
                          </div>
                          <div className="restaurant-items-count">{`X ${item.quantity}`}</div>
                        </div>
                        <div className="restaurant-items-price">
                          {`Rp${numberFormat(item.price, 0, '.', '.')}`}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div />
            </OrderItems>
            <div style={{ height: 184, width: '100vw' }} />
            <div className="confirm-button-container">
              <div className="bottom-tax">
                <span className="left">Food Tax</span>
                <span className="right">
                  {`Rp ${numberFormat(totalRestaurantTax, 0, '.', '.')}`}
                </span>
              </div>
              <div className="botton-total">
                <span className="left">Total Amount</span>
                <span className="right">
                  {`Rp ${numberFormat(totalSales, 0, '.', '.')}`}
                </span>
              </div>
            </div>
          </div>
        )}
      </CheckOutPageContainer>
    );
  }
}

CheckOutPage.propTypes = {
  tableNo: PropTypes.string.isRequired,
  outletCode: PropTypes.number.isRequired,
};

export default CheckOutPage;
