import React from 'react';
import { createStructuredSelector } from 'reselect';
import Qraved from '../../composers/Main';
import { getShortUrlDetail, getShortUrlDetailFailed } from './ShortUrl.actions';
import ShortUrl from './ShortUrl.component';
import { REDUCER_KEY, SAGA_KEY } from './ShortUrl.constants';
import reducer from './ShortUrl.reducer';
import saga from './ShortUrl.saga';
import {
  makeSelectRedirectLink,
  makeSelectErrorMessage,
  makeSelectLosEvent,
  makeSelectSourceTypeName,
  makeSelectRestaurantId,
  makeSelectRestaurantName,
  makeSelectUTMSource,
  makeSelectBrandId,
} from './ShortUrl.selectors';

const mapStateToProps = createStructuredSelector({
  redirectLink: makeSelectRedirectLink(),
  errorMessage: makeSelectErrorMessage(),
  sourceTypeName: makeSelectSourceTypeName(),
  losEvent: makeSelectLosEvent(),
  restaurantId: makeSelectRestaurantId(),
  restaurantName: makeSelectRestaurantName(),
  utmSource: makeSelectUTMSource(),
  brandId: makeSelectBrandId(),
});

const mapDispatchToProps = dispatch => ({
  onGetShortUrlDetail: shortUrlId => dispatch(getShortUrlDetail(shortUrlId)),
  onGetShortUrlDetailFailed: errorMessage =>
    dispatch(getShortUrlDetailFailed(errorMessage)),
});
const ShortUrlContainer = props => {
  return <ShortUrl {...props} />;
};
export default Qraved({
  connect: {
    mapStateToProps,
    mapDispatchToProps,
  },
  injectReducer: { key: REDUCER_KEY, reducer },
  injectSaga: { key: SAGA_KEY, saga },
  trackingOpts: true,
})(ShortUrlContainer);
