import { put, call, takeLatest } from 'redux-saga/effects';

import { CHECK_SHORT_URL_DETAIL } from './ShortUrl.constants';
import {
  getShortUrlDetailSucceed,
  getShortUrlDetailFailed,
} from './ShortUrl.actions';
import ShortUrlService from '../../service/api/ShortUrl';

export function* getShortUrlDetail(action) {
  try {
    const { shortUrlId } = action;
    const response = yield call(ShortUrlService.getShortUrlDetail, {
      shortUrlId,
    });
    if (response) {
      yield put(getShortUrlDetailSucceed(response));
    } else {
      yield put(getShortUrlDetailFailed('error'));
    }
  } catch (e) {
    yield put(getShortUrlDetailFailed('error'));
  }
}

export default function* shortUrlSaga() {
  yield takeLatest(CHECK_SHORT_URL_DETAIL, getShortUrlDetail);
}
