import produce from 'immer';
import _ from 'lodash';

import { CHECK_PORTAL_ERROR, CHECK_PORTAL_SUCCEED } from './Portal.constants';

export const initialState = {
  redirectLink: '',
  sourceType: 0,
  sourceName: '',
  errorMessage: '',
};

/* eslint-disable default-case, no-param-reassign, no-unused-vars */
const PortalReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CHECK_PORTAL_SUCCEED: {
        const { payload = {} } = action;
        const redirectLink = _.get(payload, 'result.redirect_link', '');
        draft.redirectLink = redirectLink;
        if (redirectLink) {
          draft.errorMessage = '';
        } else {
          draft.errorMessage = 'Invalid Link!';
        }
        draft.sourceName = _.get(payload, 'result.entity.name', '');
        draft.sourceType = _.get(payload, 'result.target_type', 0);
        break;
      }
      case CHECK_PORTAL_ERROR: {
        draft.redirectLink = '';
        draft.errorMessage = action.errorMessage;
        break;
      }
    }
  });

export default PortalReducer;
