import React from 'react';
import styled from 'styled-components';

const EasyLoadingContainer = styled.div`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    50% {
      background: rgb(0, 0, 0);
    }
  }

  .loading {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 0.25rem solid rgb(255, 255, 255);
    border-top-color: rgb(210, 0, 0);
    animation: spin 1s infinite linear;
  }
  &:before {
    left: -12px;
  }
  &:after {
    left: 12px;
    animation-delay: 500ms;
  }
`;

const EasyLoading = () => {
  return (
    <EasyLoadingContainer>
      <div className="loading" />
    </EasyLoadingContainer>
  );
};

export default EasyLoading;
