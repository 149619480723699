import React, { Component } from 'react';

import {
  DigitalMyBookingContainer,
  ProductItemImage,
} from './DigitalMyBooking.components';
import { LeftIcon, OrderItems, numberFormat } from '../DigitalMenus.components';
import Loading from '../../../components/Loading';
import DigitalMenusService from '../../../service/api/DigitalMenus';

class DigitalMyBooking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEmpty: true,
      orderLines: [],
      totalRestaurantTax: 0,
      totalSales: 0,
      loading: true,
    };
  }

  async componentDidMount() {
    const { outletCode, tableNo } = this.props;
    const response = await DigitalMenusService.doGetMyOrder({
      requestID: `${outletCode}-${tableNo}`,
    });

    if (response.code === 200) {
      const { orderCalculation, orderLines } = response.data;
      if (orderCalculation && orderLines) {
        const totalRestaurantTax = orderCalculation.find(
          item => item.key === 'totalRestaurantTax',
        ).value;
        const totalSales = orderCalculation.find(
          item => item.key === 'totalSales',
        ).value;
        this.setState({
          isEmpty: false,
          orderLines,
          totalRestaurantTax,
          totalSales,
          loading: false,
        });
      } else {
        this.setState({
          isEmpty: true,
          loading: false,
        });
      }
    } else if (response.code === 1155) {
      this.setState({
        isEmpty: true,
        loading: false,
      });
    }
  }

  render() {
    const {
      isEmpty,
      orderLines,
      totalRestaurantTax,
      totalSales,
      loading,
    } = this.state;
    const { tableNo, switchPage } = this.props;
    return (
      <DigitalMyBookingContainer>
        <div className="header">
          <div
            style={{ position: 'absolute', left: 11 }}
            onClick={() => switchPage(1, true)}
          >
            <LeftIcon />
          </div>
          <span>My Order</span>
        </div>
        <div className="table-name">
          <span>{`Table Number - ${tableNo}`}</span>
        </div>
        {loading ? (
          <Loading mt={250} />
        ) : (
          <div>
            {isEmpty ? (
              <div>
                <div className="empty-message">You havn’t order anything</div>
                <div className="empty-button-container">
                  <div className="empty-button" onClick={() => switchPage(1)}>
                    Back to Menu List
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <OrderItems>
                  <div className="restaurant-items-title">Items</div>
                  <div className="restaurant-items">
                    {orderLines &&
                      orderLines.map(item => {
                        return (
                          <div
                            className="restaurant-item"
                            key={item.menuItemCode}
                          >
                            <div>
                              <ProductItemImage bg={item.imageURL} />
                            </div>
                            <div className="restaurant-items-count-name">
                              <div className="restaurant-items-name">
                                {item.name}
                              </div>
                              <div className="restaurant-items-count">{`X ${item.quantity}`}</div>
                            </div>
                            <div className="restaurant-items-price">
                              {`Rp${numberFormat(item.price, 0, '.', '.')}`}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div />
                </OrderItems>
                <div style={{ height: 110, width: '100vw' }} />
                <div className="confirm-button-container">
                  <div className="bottom-tax">
                    <span className="left">Food Tax</span>
                    <span className="right">
                      {`Rp ${numberFormat(totalRestaurantTax, 0, '.', '.')}`}
                    </span>
                  </div>
                  <div className="botton-total">
                    <span className="left">Total Amount</span>
                    <span className="right">
                      {`Rp ${numberFormat(totalSales, 0, '.', '.')}`}
                    </span>
                  </div>
                  <div
                    className={
                      tableNo === ''
                        ? 'confirm-button disabled'
                        : 'confirm-button'
                    }
                    onClick={this.clickHandle}
                  >
                    <div className="left" onClick={() => switchPage(1, true)}>
                      Order More
                    </div>
                    <div className="right" onClick={() => switchPage(4)}>
                      Check Out
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </DigitalMyBookingContainer>
    );
  }
}

export default DigitalMyBooking;
