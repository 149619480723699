import { put, call, takeLatest } from 'redux-saga/effects';

import { CHECK_PORTAL } from './Portal.constants';
import { checkPortalSucceed, checkPortalError } from './Portal.actions';
import PortalService from '../../service/api/Portal';

export function* checkPortal(action) {
  try {
    const { portalType, seoKeyword } = action;
    console.log('saga: ', {
      portalType,
      seoKeyword,
    });
    const response = yield call(PortalService.doCheckPortal, {
      portalType,
      seoKeyword,
    });
    if (response) {
      yield put(checkPortalSucceed(response));
    } else {
      yield put(checkPortalError('error'));
    }
  } catch (e) {
    yield put(checkPortalError('error'));
  }
}

export default function* portalSaga() {
  yield takeLatest(CHECK_PORTAL, checkPortal);
}
