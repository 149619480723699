import React from 'react';
import { createStructuredSelector } from 'reselect';

import Qraved from '../../composers/Main';
import reducer from './QRCode.reducer';
import { REDUCER_KEY, SAGA_KEY } from './QRCode.constants';
import { makeSelectQRCode, makeSelectErrorMessage } from './QRCode.selectors';
import QRCode from './QRCode.component';
import { setErrorMessage, checkQRCode } from './QRCode.actions';
import saga from './QRCode.saga';

const mapStateToProps = createStructuredSelector({
  qrcode: makeSelectQRCode(),
  errorMessage: makeSelectErrorMessage(),
});

const mapDispatchToProps = dispatch => ({
  onCheckQRCode: id => dispatch(checkQRCode(id)),
  onSetErrorMessage: errorMessage => dispatch(setErrorMessage(errorMessage)),
});

const StampContainer = props => {
  return <QRCode {...props} />;
};

export default Qraved({
  connect: {
    mapStateToProps,
    mapDispatchToProps,
  },
  injectReducer: { key: REDUCER_KEY, reducer },
  injectSaga: { key: SAGA_KEY, saga },
  tracking: true,
})(StampContainer);
