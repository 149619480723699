import React from 'react';
import { createStructuredSelector } from 'reselect';

import Qraved from '../../composers/Main';
import DigitalMenus from './DigitalMenus.component';
import { REDUCER_KEY, SAGA_KEY } from './DigitalMenus.constants';
import reducer from './DigitalMenus.reducer';
import saga from './DigitalMenus.saga';
import {
  getStoreAndMenuAction,
  checkOrderPriceAction,
  submitOrderAction,
  getMyOrderAction,
  switchPageAction,
  changeSelectedFoodAction,
  clearCart,
} from './DigitalMenus.action';
import {
  makeSelectStoreAndMenu,
  makeSelectCurrentPage,
  makeSelectSelectedFood,
  makeSelectOutside,
  makeSelectSelectedFoodNumber,
  makeSelectTotal,
  makeSelectTax,
  makeSelectPage2loading,
  makeSelectPage1loading,
  makeSelectMinCharge,
} from './DigitalMenus.selectors';

const mapStateToProps = createStructuredSelector({
  data: makeSelectStoreAndMenu(),
  currentPage: makeSelectCurrentPage(),
  selectedFood: makeSelectSelectedFood(),
  outside: makeSelectOutside(),
  selectedFoodNumber: makeSelectSelectedFoodNumber(),
  total: makeSelectTotal(),
  tax: makeSelectTax(),
  page2loading: makeSelectPage2loading(),
  page1loading: makeSelectPage1loading(),
  minCharge: makeSelectMinCharge(),
});

const mapDispatchToProps = dispatch => ({
  getStoreAndMenu: outletCode => dispatch(getStoreAndMenuAction(outletCode)),
  getMyOrder: requestId => dispatch(getMyOrderAction(requestId)),
  checkOrderPrice: payload => dispatch(checkOrderPriceAction(payload)),
  submitOrder: payload => dispatch(submitOrderAction(payload)),
  switchPage: (page, clearmenu) => dispatch(switchPageAction(page, clearmenu)),
  changeSelectedFood: (payload, number) =>
    dispatch(changeSelectedFoodAction(payload, number)),
  clearCartAction: () => dispatch(clearCart()),
});

const DigitalMenusContainer = props => <DigitalMenus {...props} />;

export default Qraved({
  connect: {
    mapStateToProps,
    mapDispatchToProps,
  },
  injectReducer: { key: REDUCER_KEY, reducer },
  injectSaga: { key: SAGA_KEY, saga },
})(DigitalMenusContainer);
