import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Loading from '../../../components/Loading';
import {
  SelectMenusContainer,
  HeaderError,
  MenuTopBanner,
  MenuTopInfo,
  Line,
  Menus,
  SubIcon,
  AddIcon,
  MenuProduct,
  ProductItemImage,
  numberFormat,
  MenuCartEmpty,
  MenuCart,
  CartShow,
} from '../DigitalMenus.components';
import { Images } from '../../../themes';

const isMenuA = (currentMenu, selectedMenu) => {
  if (currentMenu === selectedMenu) {
    return 'activate-menu';
  }

  return '';
};

const isLastMenu = (index, length) => {
  if (index + 1 === length) {
    return 'last-menu';
  }
  return '';
};

const SelectMenus = props => {
  const {
    loading,
    outside,
    tableNo,
    switchPage,
    menus,
    addMenuFood,
    subMenuFood,
    selectedFood,
    selectedFoodNumber,
    minCharge,
    name,
    taggerCart,
    showCart,
    clearCart,
  } = props;
  const [isAnimation, setIsAnimation] = useState(false);
  const [menu, setMenu] = useState(null);

  const addMenuFoodHandel = (e, foodItem) => {
    addMenuFood(foodItem);
    setIsAnimation(true);
  };

  const getAddButton = (isSelected, foodItem, menuItem) => {
    if (!isSelected) {
      return (
        <div
          className="add-button"
          onClick={e => {
            addMenuFoodHandel(e, {
              ...foodItem,
              menuGroupCode: menuItem.menuGroupCode,
            });
          }}
          width={200}
        >
          Add
        </div>
      );
    }
    return (
      <div className="change-number">
        <span onClick={() => subMenuFood(foodItem)}>
          <SubIcon />
        </span>
        <span>{isSelected.number}</span>
        <span
          onClick={e => {
            addMenuFoodHandel(e, {
              ...foodItem,
              menuGroupCode: menuItem.menuGroupCode,
            });
          }}
        >
          <AddIcon />
        </span>
      </div>
    );
  };

  const getCurrentPrice = () => {
    let price = 0;
    selectedFood.forEach(item => {
      price += item.number * item.price;
    });

    return price;
  };

  const getMenuCartClass = () => {
    const currentPrice = getCurrentPrice();
    if (!selectedFoodNumber || currentPrice < minCharge) {
      return 'order-now disabled';
    }

    return 'order-now';
  };

  const getOrderNowButton = () => {
    const currentPrice = getCurrentPrice();
    if (minCharge && currentPrice < minCharge) {
      return (
        <div className="min-charge-order-now">
          <span>Min. Order</span>
          <br />
          <span>{`Rp${minCharge / 1000}k`}</span>
        </div>
      );
    }
    return (
      <div
        className={getMenuCartClass()}
        onClick={e => {
          e.stopPropagation();
          switchPage(2);
        }}
      >
        Order Now
      </div>
    );
  };

  return (
    <SelectMenusContainer>
      {!loading && !outside ? (
        <HeaderError>
          Sorry, we are not in service hours, please come back later
        </HeaderError>
      ) : null}
      <MenuTopBanner src={Images.menuToBanner} />
      <MenuTopInfo>
        <div className="info-top">
          <span className="info-top-title">{`Table Number - ${tableNo}`}</span>
          <span className="menus-right-icon" onClick={() => switchPage(3)}>
            My
            <br />
            Order
          </span>
        </div>
        <div className="price-info">
          <span>{name}</span>
        </div>
      </MenuTopInfo>
      <Line />
      {loading ? (
        <Loading mt={100} />
      ) : (
        <div>
          <Menus>
            {menus &&
              menus.map(item => (
                <a
                  key={item.menuGroupCode}
                  className={`menu-item ${isMenuA(item.menuGroupCode, menu)}`}
                  href={`#${item.menuGroupCode}`}
                  onClick={() => setMenu(item.menuGroupCode)}
                >
                  {item.description}
                </a>
              ))}
          </Menus>
          <Line />
          <MenuProduct>
            {menus &&
              menus.map(menuItem => {
                return (
                  <div
                    key={menuItem.menuGroupCode}
                    className="menu-product-item"
                    id={menuItem.menuGroupCode}
                  >
                    <div className="menu-product-item-title">
                      {menuItem.description}
                    </div>
                    <div className="products">
                      {menuItem.menus &&
                        menuItem.menus.map((foodItem, index) => {
                          const isSelected = selectedFood.find(
                            selectedFoodItem =>
                              selectedFoodItem.menuItemCode ===
                              foodItem.menuItemCode,
                          );
                          return (
                            <div
                              key={foodItem.menuItemCode}
                              className={`product-item-container ${isLastMenu(
                                index,
                                menuItem.menus.length,
                              )}`}
                            >
                              <div className="product-item">
                                <ProductItemImage
                                  className="product-item-image"
                                  bg={foodItem.imageURL}
                                />
                                <div className="product-item-info">
                                  <div className="product-item-info-top">
                                    <div className="product-item-info-title">
                                      {foodItem.name}
                                    </div>
                                    <div className="product-item-info-info">
                                      {foodItem.description}
                                    </div>
                                  </div>
                                  <div className="product-item-info-price">
                                    {`Rp${numberFormat(
                                      foodItem.price,
                                      0,
                                      '.',
                                      '.',
                                    )}`}
                                  </div>
                                </div>
                                {outside
                                  ? getAddButton(isSelected, foodItem, menuItem)
                                  : null}
                              </div>
                              <Line />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </MenuProduct>
        </div>
      )}
      <MenuCartEmpty />
      <MenuCart onClick={taggerCart}>
        <div className="menu-cart-logo">
          <div className="menu-cart-and-number">
            <img
              id="cart-image"
              src={Images.cart}
              className={isAnimation ? 'cart-animation' : ''}
              onAnimationEnd={() => setIsAnimation(false)}
              alt=""
            />
            {!!selectedFoodNumber && (
              <div className="menu-cart-number">{selectedFoodNumber}</div>
            )}
          </div>
          <div className="menu-cart-info">
            <span className="total-amount">Total Amount</span>
            <span>{`Rp ${numberFormat(getCurrentPrice(), 0, '.', '.')}`}</span>
          </div>
        </div>
        {getOrderNowButton()}
      </MenuCart>
      <CartShow show={showCart}>
        <div className="down-block" onClick={taggerCart} />
        <div className="cart-items">
          <div className="title">
            <span className="left">Order Items</span>
            <span className="right" onClick={clearCart}>
              Clear Cart
            </span>
          </div>
          <Line />
          <div className="menus">
            {selectedFood &&
              selectedFood.map(item => {
                return (
                  <div
                    key={`cart-${item.menuItemCode}`}
                    className="product-item-container"
                  >
                    <div className="product-item">
                      <img
                        className="product-item-image"
                        src={item.imageURL}
                        alt=""
                      />
                      <div className="product-item-info">
                        <div className="product-item-info-top">
                          <div className="product-item-info-title">
                            {item.name}
                          </div>
                        </div>
                        <div className="product-item-info-price">
                          {`Rp${numberFormat(item.price, 0, '.', '.')}`}
                        </div>
                      </div>
                      <div className="change-number">
                        <span onClick={() => subMenuFood(item)}>
                          <SubIcon />
                        </span>
                        <span>{item.number}</span>
                        <span onClick={e => addMenuFoodHandel(e, item)}>
                          <AddIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </CartShow>
    </SelectMenusContainer>
  );
};

SelectMenus.propTypes = {
  loading: PropTypes.bool.isRequired,
  outside: PropTypes.bool.isRequired,
  tableNo: PropTypes.string.isRequired,
  switchPage: PropTypes.func.isRequired,
  menus: PropTypes.array.isRequired,
  addMenuFood: PropTypes.func.isRequired,
  subMenuFood: PropTypes.func.isRequired,
  selectedFood: PropTypes.array.isRequired,
  selectedFoodNumber: PropTypes.number.isRequired,
  minCharge: PropTypes.number.isRequired,
  name: PropTypes.string,
  taggerCart: PropTypes.func.isRequired,
  showCart: PropTypes.bool.isRequired,
  clearCart: PropTypes.func.isRequired,
};

SelectMenus.defaultProps = {
  name: '',
};

export default SelectMenus;
