/* eslint-disable max-lines-per-function */

import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import ReactGA from 'react-ga';

import InjectSaga from '../../utils/injectSaga';
import { isObject, isPresent } from '../../utils/conditionals';
import InjectReducer from '../../utils/injectReducer';
import LosSDK from '../../utils/loading-los';
import Config from '../../config';

const WithIOkay = (opts = {}) => Component => {
  const {
    connect: connectOpts = null,
    injectReducer: reducerOpts = null,
    injectSaga: sagaOpts = null,
    props: propsOpts = null,
    tracking: trackingOpts = false,
  } = opts;

  const enhancers = [];

  if (isObject(connectOpts)) {
    const { mapStateToProps = null, mapDispatchToProps = null } = connectOpts;
    enhancers.push(connect(mapStateToProps, mapDispatchToProps));
  }

  if (isObject(reducerOpts)) {
    enhancers.push(InjectReducer(reducerOpts));
  }

  if (isObject(sagaOpts)) {
    enhancers.push(InjectSaga(sagaOpts));
  }

  if (isPresent(propsOpts)) {
    enhancers.push(withProps(propsOpts));
  }

  if (trackingOpts) {
    LosSDK(window, document);
    window.imgLos.getInstance().init(Config.LOS_API_KEY, null, {
      includeReferrer: true,
      includeUtm: true,
    });
    ReactGA.initialize(Config.GA_ID);
  }

  // TODO: Create more readable display name in the future for this generic HOC:)
  const composedComponent = compose(...enhancers)(Component);

  return composedComponent;
};

export default WithIOkay;
