import {
  CHECK_SHORT_URL_DETAIL,
  SET_SHORT_URL_SUCCEED,
  SET_SHORT_URL_ERROR,
} from './ShortUrl.constants';

export const getShortUrlDetail = shortUrlId => ({
  type: CHECK_SHORT_URL_DETAIL,
  shortUrlId,
});

export const getShortUrlDetailSucceed = payload => ({
  type: SET_SHORT_URL_SUCCEED,
  payload,
});

export const getShortUrlDetailFailed = errorMessage => ({
  type: SET_SHORT_URL_ERROR,
  errorMessage,
});
