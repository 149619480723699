import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import CheckOutPage from './CheckOutPage/CheckOutPage';
import SelectMenu from './SelectMenu/SelectMenu';
import CartPage from './CartPage/CartPage';
import DigitalMyBooking from './DigitalMyBooking/DigitalMyBooking';
import { DigitalMenusContainer } from './DigitalMenus.components';

const SELECT_MENU_PAGE = 1;
const ORDER_MENU_PAGE = 2;
const MY_BOOKING_PAGE = 3;
const CHECK_OUT_PAGE = 4;

const DigitalMenus = props => {
  const {
    checkOrderPrice,
    submitOrder,
    match,
    selectedFood,
    clearCartAction,
    history,
    currentPage,
    selectedFoodNumber,
    data,
    page2loading,
    changeSelectedFood,
    total,
    tax,
    outside,
    page1loading,
    switchPage,
    minCharge,
    getStoreAndMenu,
  } = props;
  const [showCart, setShowCart] = useState(false);
  const { outletCode, tableNo } = match.params;
  const { menuGroups, name } = data;
  useEffect(() => {
    getStoreAndMenu(outletCode);
  }, [outletCode, getStoreAndMenu]);

  const calculateOrderPricing = () => {
    const payload = {
      requestId: `${outletCode}-${tableNo}`,
      destinationStore: {
        outletCode,
      },
      user: {
        name: `${tableNo}`,
      },
      orderLines: selectedFood.map(item => ({
        menuGroupCode: item.menuGroupCode,
        menuItemCode: item.menuItemCode,
        quantity: item.number,
        setItems: [],
      })),
    };

    checkOrderPrice(payload);
  };

  const bookingOrder = () => {
    const payload = {
      requestId: `${outletCode}-${tableNo}`,
      destinationStore: {
        outletCode,
      },
      user: {
        name: `${tableNo}`,
      },
      orderLines: selectedFood.map(item => ({
        menuGroupCode: item.menuGroupCode,
        menuItemCode: item.menuItemCode,
        quantity: item.number,
        setItems: [],
      })),
    };

    submitOrder(payload);
  };

  const taggerCart = () => {
    if (selectedFood.length) {
      setShowCart(!showCart);
    }
  };

  const clearCart = () => {
    clearCartAction();
    setShowCart(false);
  };

  const subMenuFood = async food => {
    const selectedFoodCopy = _.cloneDeep(selectedFood);
    const index = selectedFood
      .map(item => item.menuItemCode)
      .indexOf(food.menuItemCode);
    const currentFood = selectedFoodCopy[index];
    const currenFoodNumber = currentFood.number - 1;

    if (!currenFoodNumber) {
      selectedFoodCopy.splice(index, 1);
    } else {
      selectedFoodCopy[index].number -= 1;
    }

    changeSelectedFood(selectedFoodCopy, selectedFoodNumber - 1);
    if (!selectedFood) {
      setShowCart(false);
    }
  };

  const addMenuFood = async food => {
    const selectedFoodCopy = _.cloneDeep(selectedFood);
    const index = selectedFood
      .map(item => item.menuItemCode)
      .indexOf(food.menuItemCode);
    if (index === -1) {
      selectedFoodCopy.push({
        ...food,
        number: 1,
      });
    } else {
      selectedFoodCopy[index].number += 1;
    }

    changeSelectedFood(selectedFoodCopy, selectedFoodNumber + 1);
  };

  const getCurrentPage = () => {
    switch (currentPage) {
      case SELECT_MENU_PAGE:
        return (
          <SelectMenu
            loading={page1loading}
            tableNo={tableNo}
            outletCode={outletCode}
            menus={menuGroups}
            name={name}
            history={history}
            selectedFoodNumber={selectedFoodNumber}
            selectedFood={selectedFood}
            addMenuFood={addMenuFood}
            subMenuFood={subMenuFood}
            switchPage={switchPage}
            taggerCart={taggerCart}
            clearCart={clearCart}
            showCart={showCart}
            total={total}
            tax={tax}
            outside={outside}
            minCharge={minCharge}
          />
        );
      case ORDER_MENU_PAGE:
        return (
          <CartPage
            tableNo={tableNo}
            outletCode={outletCode}
            menus={selectedFood}
            total={total}
            tax={tax}
            bookingOrder={bookingOrder}
            switchPage={switchPage}
            calculateOrderPricing={calculateOrderPricing}
          />
        );
      case MY_BOOKING_PAGE:
        return (
          <DigitalMyBooking
            loading={page2loading}
            switchPage={switchPage}
            tableNo={tableNo}
            outletCode={outletCode}
            menus={selectedFood}
            total={total}
            tax={tax}
          />
        );
      case CHECK_OUT_PAGE:
        return (
          <CheckOutPage
            switchPage={switchPage}
            tableNo={tableNo}
            outletCode={outletCode}
          />
        );
      default:
        return null;
    }
  };

  return <DigitalMenusContainer>{getCurrentPage()}</DigitalMenusContainer>;
};

DigitalMenus.propTypes = {
  getStoreAndMenu: PropTypes.func.isRequired,
  checkOrderPrice: PropTypes.func.isRequired,
  submitOrder: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  selectedFood: PropTypes.array.isRequired,
  clearCartAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  selectedFoodNumber: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  page2loading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  outside: PropTypes.bool.isRequired,
  page1loading: PropTypes.bool.isRequired,
  switchPage: PropTypes.func.isRequired,
  changeSelectedFood: PropTypes.func.isRequired,
  minCharge: PropTypes.number.isRequired,
};

export default DigitalMenus;
