import { createSelector } from 'reselect';

import { REDUCER_KEY } from './Portal.constants';
import { initialState } from './Portal.reducer';

const selectPortal = state => state[REDUCER_KEY] || initialState;

const makeSelectRedirectLink = () =>
  createSelector(selectPortal, portalState => portalState.redirectLink);

const makeSelectSourceType = () =>
  createSelector(selectPortal, portalState => portalState.sourceType);

const makeSelectSourceName = () =>
  createSelector(selectPortal, portalState => portalState.sourceName);

const makeSelectErrorMessage = () =>
  createSelector(selectPortal, pageState => pageState.errorMessage);

export {
  makeSelectRedirectLink,
  makeSelectErrorMessage,
  makeSelectSourceType,
  makeSelectSourceName,
};
