import ReactGA from 'react-ga';
import { PV_SCAN_QR_CODE, PV_SCAN_QR_CODE_EVENT } from '../constants/events';
import {
  SOURCEWHATSAPP,
  SOURCELINKTREE,
  ACTION_VIEW_PORTAL_PAGE,
  ACTION_VIEW_PORTAL_PAGE_EVENT_NAME,
} from '../containers/Portal/Portal.constants';
import {
  ACTION_VIEW_SHORT_URL_PAGE,
  ACTION_VIEW_SHORT_URL_PAGE_EVENT_NAME,
} from '../containers/ShortUrl/ShortUrl.constants';
import queryString from 'querystring';
const _ = require('lodash');
const { EventEmitter2 } = require('eventemitter2');

function EventEmitter() {
  EventEmitter2.call(this);
}

EventEmitter.prototype = _.create(EventEmitter2.prototype, {
  constructor: EventEmitter,
});

EventEmitter.prototype.addSubscriber = function addSubscriber(subscriber) {
  subscriber(this);
};

const AmplitudeSubscriber = eventEmitter => {
  eventEmitter.on(PV_SCAN_QR_CODE, event => {
    const link = event.redirect_link||event.web_link;
    if(link.includes('/web-delivery/brand/')){
      event.brand_id = event.target_id;
      event.brand_name = event.target_name;
    }
    if(link.includes('/web-delivery/restaurant/')){
      event.restaurant_id = event.target_id;
      event.restaurant_name = event.target_name;
    }
    if(event.target_type==='Restaurant'){
      event.restaurant_id = event.target_id;
      event.restaurant_name = event.target_name;
    }
    if(event.restaurant_name){
      event.restaurant_name = event.restaurant_name.split(',')[0]
    }
    const position = link.indexOf('?');
    const {tableNo,utm_source} = queryString.parse(link.substring(position+1,link.length));
    event.content.table_no = tableNo||'';
    event.content.utm_source = utm_source||'';
    window.imgLos.getInstance().logEvent(PV_SCAN_QR_CODE_EVENT, event);
  });

  eventEmitter.on(ACTION_VIEW_PORTAL_PAGE, event => {
    let sourceType = '';
    if (SOURCEWHATSAPP.includes(event.sourceType)) {
      sourceType = 'whatsApp';
    }
    if (SOURCELINKTREE.includes(event.sourceType)) {
      sourceType = 'linkTree';
    }
    window.imgLos.getInstance().logEvent(ACTION_VIEW_PORTAL_PAGE_EVENT_NAME, {
      Source: sourceType,
      From: event.sourceName,
      Redirect: event.redirectLink,
      Campaign: event.seoKeyword,
    });
    ReactGA.event({
      category: sourceType,
      action: ACTION_VIEW_PORTAL_PAGE_EVENT_NAME,
      value: event.redirectLink,
    });
  });

  eventEmitter.on(ACTION_VIEW_SHORT_URL_PAGE, event => {
    const { From,
      OrderType,
      RestaurantId,
      Source,
      TargetType,
      UTMSource,
      BrandId,
      BrandName
    } = event.losEvent;
    const position = event.redirectLink.indexOf('?');
    const {tableNo,utm_source} = queryString.parse(event.redirectLink.substring(position+1,event.redirectLink.length));
    window.imgLos
      .getInstance()
      .logEvent(ACTION_VIEW_SHORT_URL_PAGE_EVENT_NAME, {
        Redirect: event.redirectLink,
        From,
        URL:window.location.href,
        OrderType,
        brand_id:BrandId,
        brand_name:BrandName,
        Restaurant_ID: RestaurantId,
        RestaurantName:event.restaurantName,
        Source,
        tableNo,
        TargetType,
        UTM_Source: utm_source||UTMSource,
      });
    ReactGA.event({
      category: event.losEvent.Source,
      action: ACTION_VIEW_SHORT_URL_PAGE_EVENT_NAME,
      value: event.redirectLink,
    });
  });
};
const eventEmitter = new EventEmitter();
eventEmitter.addSubscriber(AmplitudeSubscriber);

export default eventEmitter;
