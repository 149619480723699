import ApiConstants from '../../../constants/ApiConstants';
import { RequestClientClass } from '../../../utils/requestClient';
import Config from '../../../config';

const requestClient = () => new RequestClientClass(Config.QRAVED_SERVER);

const getShortUrlDetail = async payload => {
  const { shortUrlId } = payload;
  const uri = ApiConstants.shortUrl.get(shortUrlId);
  const response = await requestClient()
    .setUri(uri)
    .doGet();
  return response;
};

export default {
  getShortUrlDetail,
};
