import React from 'react';
import { createStructuredSelector } from 'reselect';

import Qraved from '../../composers/Main';

import { checkPortal, checkPortalError } from './Portal.actions';
import Portal from './Portal.component';
import { REDUCER_KEY, SAGA_KEY } from './Portal.constants';
import reducer from './Portal.reducer';
import saga from './Portal.saga';
import {
  makeSelectRedirectLink,
  makeSelectErrorMessage,
  makeSelectSourceName,
  makeSelectSourceType,
} from './Portal.selectors';

const mapStateToProps = createStructuredSelector({
  redirectLink: makeSelectRedirectLink(),
  errorMessage: makeSelectErrorMessage(),
  sourceName: makeSelectSourceName(),
  sourceType: makeSelectSourceType(),
});

const mapDispatchToProps = dispatch => ({
  onCheckPortal: (portalType, seoKeyword) =>
    dispatch(checkPortal(portalType, seoKeyword)),
  onCheckPortalFailed: errorMessage => dispatch(checkPortalError(errorMessage)),
});

const PortalContainer = props => <Portal {...props} />;

export default Qraved({
  connect: {
    mapStateToProps,
    mapDispatchToProps,
  },
  injectReducer: { key: REDUCER_KEY, reducer },
  injectSaga: { key: SAGA_KEY, saga },
  trackingOpts: true,
})(PortalContainer);
