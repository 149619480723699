import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Routers from '../../routers/Routers';

import QRCode from '../QRCode';
import DigitalMenus from '../DigitalMenus';
import Portal from '../Portal';
import ShortUrl from '../ShortUrl';

const App = () => {
  return (
    <Router>
      {/* <Route path={Routers.stamp} component={Stamp} /> */}
      <Switch>
        <Route path={Routers.qrcode} component={QRCode} />
        <Route path={Routers.digitalMenus} component={DigitalMenus} />
        <Route path={Routers.portalTo} component={Portal} />
        <Route path={Routers.shortUrlRedirect} component={ShortUrl} />
      </Switch>
    </Router>
  );
};

export default App;
