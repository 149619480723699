import _ from 'lodash';
import produce from 'immer';
import {
  SET_SHORT_URL_SUCCEED,
  SET_SHORT_URL_ERROR,
} from './ShortUrl.constants';

export const initialState = {
  redirectLink: '',
  sourceTypeName: '',
  errorMessage: '',
  losEvent: {},
  restaurantId: '',
  RestaurantName: '',
  UTMSource: '',
};

/* eslint-disable default-case, no-param-reassign, no-unused-vars */
const ShorUrlReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_SHORT_URL_SUCCEED: {
        const { payload = {} } = action;
        const redirectLink = _.get(payload, 'result.link', '');
        draft.redirectLink = redirectLink;
        if (redirectLink) {
          draft.errorMessage = '';
        } else {
          draft.errorMessage = 'Invalid Link!';
        }
        draft.losEvent = _.get(payload, 'result.entity', '');
        draft.sourceTypeName = _.get(payload, 'result.entity.source', '');
        draft.restaurantId = _.get(payload, 'result.entity.RestaurantId', '');
        draft.RestaurantName = _.get(
          payload,
          'result.entity.RestaurantName',
          '',
        );
        draft.UTMSource = _.get(payload, 'result.entity.UTMSource', '');
        break;
      }
      case SET_SHORT_URL_ERROR: {
        draft.redirectLink = '';
        draft.errorMessage = action.errorMessage;
        break;
      }
      default:
        break;
    }
  });

export default ShorUrlReducer;
