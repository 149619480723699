export const REDUCER_KEY = 'spring-qraved-qrcode';
export const SAGA_KEY = 'spring-qraved-qrcode-saga';
export const LOADING = 'Loading...';

// asset type
export const ASSET_TYPE_RESTANRUNT = 1;
export const ASSET_TYPE_JOURNAL = 2;
export const ASSET_TYPE_PROMO = 3;
export const ASSET_TYPE_QOA = 4;
export const ASSET_TYPE_ROOM_SERVICE = 5;
export const ASSET_TYPE_QRO = 6;
export const ASSET_TYPE_MENU = 41;
export const DIGITAL_MENU = 42;
export const ASSET_TYPE_OTHER = 220;
export const ASSET_TYPES = [
  { id: 3, name: 'Promo' },
  { id: 1, name: 'Restaurant' },
  { id: 2, name: 'Journal' },
  { id: 41, name: 'Menu List' },
  { id: 4, name: 'Channel' },
  { id: 5, name: 'Room Service' },
  { id: 6, name: 'GRO Page' },
  { id: 42, name: 'Digital Menu' },
  { id: 220, name: 'External Link' },
  { id: 302, name: 'WA -> QDO - restaurant' },
  { id: 303, name: 'WA -> QDO - brand' },
  { id: 312, name: 'Linktree -> QDO - restaurant' },
  { id: 313, name: 'Linktree -> QDO - brand' },
  { id: 314, name: 'Linktree -> WA - restaurant' },
  { id: 315, name: 'Linktree -> WA - brand' },
  { id: 320, name: 'IG Profile -> Linktree - restaurant' },
  { id: 321, name: 'IG Profile -> Linktree - brand' },
];

// reducer
export const CHECK_QR_CODE = 'spring-qraved/qrcode/CHECK_QR_CODE';
export const CHECK_QR_CODE_SUCCEED =
  'spring-qraved/qrcode/CHECK_QR_CODE_SUCCEED';
export const CHECK_QR_CODE_ERROR = 'spring-qraved/qrcode/CHECK_QR_CODE_ERROR';

// functions
export const openAppOrWeb = (applink, weblink) => {
  if (typeof window !== 'undefined') {
    if (applink) {
      window.location.href = applink;
    }
    window.setTimeout(() => {
      if (weblink) {
        window.location.href = weblink;
      }
    }, 2000);
  }
};

const reverseStringToArray = str => {
  const splitString = str.split('');
  const reverseArray = splitString.reverse();
  return reverseArray;
};

// 生成x-y的随机数
const getRandomNumber = (x, y) => {
  return Math.floor(x + Math.random() * y);
};

const BASE_STRING = 'abcdefghigklmnopqrstuvwxyz';
// 打乱字符串
function shuffle() {
  const array = BASE_STRING.split('');
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array.join('');
}

// 根据当前时间戳生成一个随机字符串
export const getRandomStrFromTime = () => {
  const time = new Date().getTime();
  const timeArray = reverseStringToArray(`${time}`);
  const randomArray = timeArray.map(item => {
    const number = getRandomNumber(0, 2);
    return `${item}${shuffle().substr(0, number)}`;
  });
  return randomArray.join('');
};
