const getOS = () => {
  const userAgentInfo = window.navigator.userAgent;
  const Agents = ['Android', 'iPhone', 'Windows', 'Ubunte', 'Linux', 'Mac OS'];
  let flag = 'PC';
  for (let index = 0; index < Agents.length; index += 1) {
    flag = Agents[index];
    if (userAgentInfo.includes(Agents[index])) {
      if (Agents[index] === 'iPhone') {
        flag = 'iOS';
      }
      break;
    }
  }

  return flag;
};

export default {
  getOS,
};
