/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';

/**
 * number：要格式化的数字
 * decimals：保留几位小数
 * dec_point：小数点符号
 * thousands_sep：千分位符号
 * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
 * */
export const numberFormat = (
  number,
  decimals,
  dec_point,
  thousands_sep,
  roundtag,
) => {
  number = `${number}`.replace(/[^0-9+-Ee.]/g, '');
  roundtag = roundtag || 'ceil'; // "ceil","floor","round"
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep;
  const dec = typeof dec_point === 'undefined' ? '.' : dec_point;
  let s = '';
  const toFixedFix = function(n, prec) {
    const k = Math.pow(10, prec);
    console.log();

    return `${parseFloat(
      Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2),
    ) / k}`;
  };
  s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
  const re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, `$1${sep}$2`);
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
};

export const DigitalMenusContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Helvetica Neue;
  position: relative;
  @keyframes cartAnimation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-30deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .cart-animation {
    animation: 0.35s ease-out 0s cartAnimation;
  }
`;

export const SelectMenusContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const MenuTopBanner = styled.img`
  width: 100%;
`;

export const MenuTopInfo = styled.div`
  padding: 0 16px;

  .info-top {
    display: flex;
    position: relative;
    height: 54px;
    .info-top-title {
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      margin-top: 20px;
    }
  }
  .menus-right-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    position: absolute;
    top: -32px;
    right: 0px;
    font-size: 14px;
    font-weight: bold;
    background-color: #ff0000;
    text-align: center;
    color: #fff;
  }

  .price-info {
    height: 24px;
    margin-top: 10px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.3px;
    color: #999999;
  }
`;

export const Line = styled.div`
  width: 100%;
  border-top: 0.5px solid #d8d8d8;
`;

export const AddressInfo = styled.div`
  padding: 0 16px;
  .address-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.3px;
  }
  color: #ff5d34;
  .adderss-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 270px;
    border: 1px solid #ff5d34;
    box-sizing: border-box;
    border-radius: 3px;
    span {
      font-size: 12px;
    }
  }
`;

export const HOTCoupon = styled.div`
  width: 100%;
  padding: 0 16px;

  .hot-coupon {
    height: 164px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .hot-coupon-title {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.3px;
    color: #333333;
  }

  .coupon-items {
    display: flex;
    width: auto;
    overflow: auto;
    margin-top: 9px;
    .coupon-item {
      flex-shrink: 0;
      position: relative;
      height: 116px;
      width: 116px;
      border-radius: 3px;
      margin-right: 20px;
      .coupon-item-top-img {
        width: 115px;
        height: 115px;
      }
      .coupon-item-bottom-img {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
`;

export const SpecialSection = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  .special-title {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  .special-content {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
  .special-items {
    display: flex;
    width: auto;
    overflow: auto;

    .special-item {
      flex-shrink: 0;
    }
  }
`;

export const Menus = styled.div`
  display: flex;
  width: auto;
  overflow: auto;
  padding: 0 1px;
  .menu-item {
    padding: 20px 15px;
    flex-shrink: 0;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #5e5e5e;
  }
  .activate-menu {
    color: #d20000;
    border-bottom: 2px solid #d20000;
    box-sizing: border-box;
  }
`;

export const FoodList = styled.div`
  width: 100vh;
  padding: 0 16px;
`;

export const MenuCartEmpty = styled.div`
  width: 100%;
  height: 70px;
  background: #fff;
`;

export const CouponIndexEmpty = styled.div`
  width: 100%;
  height: 140px;
  background: #eaeaea;
`;

export const MenuCart = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background: #fafafa;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1;
  bottom: 0px;
  .menu-cart-logo {
    display: flex;
    .menu-cart-and-number {
      position: relative;
    }
    .menu-cart-number {
      display: flex;
      position: absolute;
      background: #d20000;
      box-sizing: border-box;
      color: #fff;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      justify-content: center;
      align-items: center;
      top: -5px;
      right: -8px;
    }
    .menu-cart-info {
      display: flex;
      flex-direction: column;
      margin-left: 18px;
      .total-amount {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #333333;
      }
      .cost {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
      }
    }
  }
  .order-now {
    width: 110px;
    height: 40px;
    background: #d20000;
    border-radius: 5px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    margin-right: 30px;
  }
  .min-charge-order-now {
    width: 110px;
    height: 40px;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 5px;
    border-radius: 5px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #333333;
  }
  .disabled {
    pointer-events: none;
    background: #999;
  }
`;

export const ProductItemImage = styled.div`
  background: url(${props => props.bg}) no-repeat center;
  background-size: cover;
`;

export const MenuProduct = styled.div`
  padding: 0 16px;
  .menu-product-item {
    display: flex;
    flex-direction: column;
    .menu-product-item-title {
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #5e5e5e;
      height: 50px;
    }
    .products {
      display: flex;
      flex-direction: column;
    }
  }

  .last-menu {
    margin-bottom: 0px !important;
  }
  .product-item-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .product-item {
    display: flex;
    margin-bottom: 15px;

    .product-item-image {
      flex-shrink: 0;
      width: 100px;
      height: 100px;
    }
    .product-item-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 14px;
      flex-shrink: 1;
      flex-grow: 1;
      .product-item-info-title {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
      }
      .product-item-info-info {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        margin-top: 11px;
        letter-spacing: -0.3px;
        color: #999999;
        height: 28px;
      }
      .product-item-info-price {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-top: 10px;
        color: #d20000;
      }
    }
  }
  .add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 90px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #d20000;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #d20000;
    align-self: flex-end;
  }
  .change-number {
    color: #d20000;
    line-height: 17px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 0;
    width: 90px;
    height: 36px;
    align-self: flex-end;
    span {
      font-size: 16px;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const OrderMenuContainer = styled.div`
  width: 100%;
  display: flex;
  background: #eaeaea;
  flex-direction: column;
`;

export const Inlineprice = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  .inline-price {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    .inline-price-name {
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
    }
    .inline-price-price {
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
    }
  }
`;

export const OrderMenuHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 0.5px 0px rgba(153, 153, 153, 0.5);
  span {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.425px;
    color: #333333;
  }
`;

export const DeliverTo = styled.div`
  display: flex;
  padding: 0 15px;
  width: 100%;
  background: #ffffff;
  .deliver-to-title {
    margin-top: 15px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
  }
  .deliver-to-body {
    height: 60px;
  }
`;

export const OrderItems = styled.div`
  display: flex;
  padding: 0 15px;
  background: #ffffff;
  margin-top: 10px;
  flex-direction: column;
  .restaurant-title {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #262626;
    margin-top: 15px;
  }
  .restaurant-adderss {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #979797;
    margin-bottom: 10px;
  }
  .restaurant-items-title {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #999999;
    margin: 10px 0;
  }
  .restaurant-item {
    display: flex;
    margin-bottom: 10px;
    img {
      width: 55px;
      height: 55px;
      border-radius: 8px;
      flex-shrink: 0;
    }
    .restaurant-items-count-name {
      flex-grow: 1;
      margin-left: 10px;
      .restaurant-items-name {
        height: 34px;
      }
      .restaurant-items-count {
        margin-top: 7px;
        color: #979797;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .restaurant-items-price {
      flex-shrink: 0;
      width: 91px;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      color: #333333;
    }
  }
`;

export const PayNowButtom = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d20000;
  position: fixed;
  z-index: 1;
  bottom: 0px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
`;

export const SelectCoupon = styled.div`
  padding: 0 15px;
  background: #fff;
  .select-coupon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    .select-coupon-left {
      display: flex;
      align-items: center;
      span {
        margin-left: 11px;
      }
    }
    .select-coupon-right {
      padding: 8px 9px;
      background: #ff9242;
      border-radius: 10px;
      height: 32px;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #ffffff;
      span {
        margin-right: 5px;
      }
    }
  }
`;

export const PaymentMethod = styled.div`
  display: flex;
  height: 64px;
  padding: 0 15px;
  background: #fff;
  align-items: center;
  .payment-method {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .payment-pay-right {
      img {
        margin-right: 26px;
      }
    }
  }
`;

export const PayNowEmpty = styled.div`
  width: 100%;
  height: 45px;
  background: #fff;
`;

export const TotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  width: 100%;
  padding: 0 15px;
`;

export const CouponListContainer = styled.div`
  width: 100%;
  display: flex;
  background: #eaeaea;
  flex-direction: column;
`;

export const SelectCouponCode = styled.div`
  height: 80px;
  background: #fff;
  padding: 25px 10px;
  display: flex;
  align-items: center;
  input {
    height: 30px;
    flex-grow: 1;
    background: rgba(234, 234, 234, 0.45);
    border-radius: 3px 0 0 3px;
    border: 0px solid;
    ::-webkit-input-placeholder {
      color: #979797;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }
    :-moz-placeholder {
      color: #979797;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }
    ::-moz-placeholder {
      color: #979797;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }
    :-ms-input-placeholder {
      color: #979797;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }
  }
  .redeem-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 69px;
    height: 30px;
    background: #ff9242;
    border-radius: 0px 3px 3px 0px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }
`;

export const CouponIndex = styled.div`
  margin-top: 15px;
  .coupon-index-item {
    display: flex;
    align-items: center;
    height: 95px;
    margin-bottom: 15px;
    margin-right: 10px;
    background-image: url('/img/coupon-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .coupon-body {
      margin-right: 25px;
      margin-left: 15px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      .coupon-body-info {
        flex-grow: 1;
        justify-content: space-between;
        display: flex;
      }
    }
    .coupon-title {
      display: flex;
      align-items: center;
      height: 20px;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;
        color: #333333;
        margin-left: 6px;
      }
    }
    .coupon-info {
      display: flex;
      overflow: hidden;
      width: 165px;
      height: 35px;
      margin-top: 6px;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.274286px;
      color: #333333;
    }
  }
  .coupon-image {
    margin-left: 30px;
    position: relative;
    .coupon-item-top-img {
      width: 75px;
      height: 75px;
    }
    .coupon-item-bottom-img {
      width: 75px;
      height: 16px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .expiring {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #f15c4f;
    margin-top: 7px;
  }
`;

export const CouponInfo = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: fixed;
  z-index: 1;
  bottom: 0px;
  box-shadow: 0px -2px 10px #d8d8d8;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.425px;
  color: #979797;
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 108px;
    height: 29px;
    background: #d20000;
    border-radius: 5px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
  }
`;

export const LoadingPage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CartShow = styled.div`
  transition: height 0.35s;
  display: flex;
  width: 100%;
  height: ${props => (props.show ? '300px' : 0)};
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  bottom: 56px;
  background: #fff;
  align-items: center;
  .down-block {
    background: #c4c4c4;
    border-radius: 5px;
    width: 34px;
    height: 4px;
    margin-top: 10px;
  }
  .cart-items {
    width: 100%;
    overflow: auto;
    max-height: 286px;
    padding: ${props => (props.show ? '15px' : 0)};
    .title {
      font-family: Roboto;
      font-style: normal;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .right {
        color: #878787;
        font-size: 14px;
        line-height: 22px;
      }
      .left {
        color: #333333;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        font-weight: normal;
      }
    }

    .product-item-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
    .product-item {
      display: flex;
      margin-top: 15px;

      .product-item-image {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
      }
      .product-item-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 14px;
        flex-shrink: 1;
        flex-grow: 1;
        .product-item-info-title {
          font-family: Helvetica Neue;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
        }
        .product-item-info-info {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-family: Helvetica Neue;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          margin-top: 11px;
          letter-spacing: -0.3px;
          color: #999999;
          height: 28px;
        }
        .product-item-info-price {
          font-family: Helvetica Neue;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-top: 10px;
          color: #d20000;
        }
      }

      .change-number {
        color: #d20000;
        line-height: 17px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-shrink: 0;
        width: 90px;
        height: 36px;
        align-self: flex-end;
        span {
          font-size: 16px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

export const HeaderError = styled.div`
  display: flex;
  width: 100vw;
  height: 36px;
  position: fixed;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  background: rgba(210, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const RightIcon = () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.78564 5L0.0356445 1.25003L0.928502 0.357178L5.57333 5L0.928502 9.64289L0.0356445 8.75004L3.78564 5Z"
      fill="#999999"
    />
  </svg>
);

export const CouponIcon = () => (
  <svg
    width="29"
    height="20"
    viewBox="0 0 29 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.1429 0H1.42857C1.04969 0 0.686328 0.15051 0.418419 0.418419C0.15051 0.686328 0 1.04969 0 1.42857V7.14286C8.88002e-09 7.52174 0.15051 7.8851 0.418419 8.15301C0.686328 8.42092 1.04969 8.57143 1.42857 8.57143C2.61 8.57143 3.57143 9.53286 3.57143 10.7143C3.57143 11.8957 2.61 12.8571 1.42857 12.8571C1.04969 12.8571 0.686328 13.0077 0.418419 13.2756C0.15051 13.5435 0 13.9068 0 14.2857V18.5714C0 18.9503 0.15051 19.3137 0.418419 19.5816C0.686328 19.8495 1.04969 20 1.42857 20H27.1429C27.5217 20 27.8851 19.8495 28.153 19.5816C28.4209 19.3137 28.5714 18.9503 28.5714 18.5714V14.2857C28.5714 13.9068 28.4209 13.5435 28.153 13.2756C27.8851 13.0077 27.5217 12.8571 27.1429 12.8571C25.9614 12.8571 25 11.8957 25 10.7143C25 9.53286 25.9614 8.57143 27.1429 8.57143C27.5217 8.57143 27.8851 8.42092 28.153 8.15301C28.4209 7.8851 28.5714 7.52174 28.5714 7.14286V1.42857C28.5714 1.04969 28.4209 0.686328 28.153 0.418419C27.8851 0.15051 27.5217 0 27.1429 0ZM25.7143 5.92286C23.6514 6.54 22.1429 8.45286 22.1429 10.7143C22.1429 12.9757 23.6514 14.89 25.7143 15.5057V17.1429H12.8571V14.2857H10V17.1429H2.85714V15.5057C4.92 14.8886 6.42857 12.9757 6.42857 10.7143C6.42857 8.45286 4.92 6.53857 2.85714 5.92286V2.85714H10V5.71429H12.8571V2.85714H25.7143V5.92286Z"
      fill="#F05E3B"
    />
  </svg>
);

export const LeftIcon = () => (
  <svg
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.82579 10.0001L11.2955 1.53039L10.2348 0.469727L1.2348 9.46973L0.704468 10.0001L1.2348 10.5304L10.2348 19.5304L11.2955 18.4697L2.82579 10.0001Z"
      fill="#333333"
    />
  </svg>
);

export const PaymentMethodRightIcon = () => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21411 8.9999L0.464111 2.24997L2.07125 0.642822L10.4319 8.9999L2.07125 17.3571L0.464111 15.75L7.21411 8.9999Z"
      fill="#999999"
    />
  </svg>
);

export const SelectCouponRightIcon = () => (
  <svg
    width="10"
    height="17"
    viewBox="0 0 10 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 8.35708L0 1.60714L1.60714 0L9.96783 8.35708L1.60714 16.7143L0 15.1071L6.75 8.35708Z"
      fill="white"
    />
  </svg>
);

export const AddIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.023 0C4.48744 0 0 4.47714 0 10C0 15.5229 4.48744 20 10.023 20C15.5585 20 20.046 15.5229 20.046 10C20.046 4.47714 15.5585 0 10.023 0ZM15.0725 11.0551H11.078V15.0495C11.078 15.6322 10.6056 16.1046 10.023 16.1046C9.44028 16.1046 8.96789 15.6322 8.96789 15.0495V11.0551H4.97343C4.39075 11.0551 3.91837 10.5827 3.91837 10C3.91837 9.41732 4.39075 8.94494 4.97343 8.94494H8.96789V4.95048C8.96789 4.3678 9.44028 3.89541 10.023 3.89541C10.6056 3.89541 11.078 4.3678 11.078 4.95048V8.94494H15.0725C15.6552 8.94494 16.1275 9.41732 16.1275 10C16.1275 10.5827 15.6552 11.0551 15.0725 11.0551Z"
      fill="#D20000"
    />
  </svg>
);

export const SubIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.48608 0 0 4.48607 0 10C0 15.5139 4.48608 20 10 20C15.5139 20 20 15.5139 20 10C20 4.48607 15.5139 0 10 0ZM10 18.75C5.17517 18.75 1.25 14.8248 1.25 9.99998C1.25 5.17515 5.17517 1.24997 10 1.24997C14.8249 1.24997 18.75 5.17515 18.75 9.99998C18.75 14.8249 14.8248 18.75 10 18.75ZM13.75 9.37501H6.25002C5.90454 9.37501 5.62501 9.65455 5.62501 10C5.62501 10.3449 5.90454 10.625 6.25002 10.625H13.75C14.0955 10.625 14.375 10.3449 14.375 10C14.375 9.65455 14.0955 9.37501 13.75 9.37501Z"
      fill="#D20000"
    />
  </svg>
);
