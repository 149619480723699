export const REDUCER_KEY = 'spring-qraved-portal';
export const SAGA_KEY = 'spring-qraved-portal-saga';
export const LOADING = 'Loading...';

export const PORTAL_TYPES = {
  wa: {
    rt: 300,
    ch_b: 301,
  },
  lt: {
    rt: 310,
    ch_b: 311,
  },
};
export const SOURCEWHATSAPP = [300, 301];
export const SOURCELINKTREE = [310, 311];

// reducer
export const CHECK_PORTAL = 'spring-qraved/portal/CHECK_PORTAL';
export const CHECK_PORTAL_SUCCEED = 'spring-qraved/portal/CHECK_PORTAL_SUCCEED';
export const CHECK_PORTAL_ERROR = 'spring-qraved/portal/CHECK_PORTAL_ERROR';

export const ACTION_VIEW_PORTAL_PAGE = 'action_view_portal_page';
export const ACTION_VIEW_PORTAL_PAGE_EVENT_NAME = 'RC - QDO Redirecting Page';
