import produce from 'immer';

import {
  SELECT_MENU_PAGE,
  GET_MENU_STORE_SUCCEED,
  CHECK_ORDER_PRICE_SUCCEED,
  SUBMIT_ORDER_SUCCEED,
  GET_MY_ORDER_SUCCEED,
  GET_MY_ORDER,
  SET_ERROR_MESSAGE,
  SWITCH_PAGE,
  CHANGE_SELECTED_FOOD,
  CLEAR_CART,
} from './DigitalMenus.constants';

export const initialState = {
  errorMessage: '',
  page2loading: true,
  page1loading: true,
  data: { menuGroups: [] },
  currentPage: SELECT_MENU_PAGE,
  selectedFood: [],
  selectedFoodNumber: 0,
  total: 0,
  tax: 0,
  outside: true,
  showCart: false,
  minCharge: 0,
};

/* eslint-disable default-case, no-param-reassign, no-unused-vars */
const Reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_ERROR_MESSAGE: {
        const { error } = action.payload;
        draft.errorMessage = error;
        draft.page1loading = false;
        draft.page2loading = false;
        break;
      }
      case GET_MENU_STORE_SUCCEED: {
        const { data } = action.payload;
        draft.data = data;
        draft.errorMessage = '';
        draft.page1loading = false;
        draft.outside = data.isServiceHours;
        break;
      }
      case CHECK_ORDER_PRICE_SUCCEED: {
        const { orderCalculations } = action.payload;
        const tax = orderCalculations.find(
          item => item.key === 'totalRestaurantTax',
        ).value;
        const total = orderCalculations.find(item => item.key === 'totalSales')
          .value;
        draft.tax = tax;
        draft.total = total;
        break;
      }
      case SUBMIT_ORDER_SUCCEED: {
        draft.currentPage = 3;
        break;
      }
      case GET_MY_ORDER_SUCCEED: {
        draft.page2loading = false;
        draft.currentPage = 3;
        break;
      }
      case GET_MY_ORDER: {
        draft.page2loading = true;
        break;
      }
      case SWITCH_PAGE: {
        const { page, clearmenu } = action;
        draft.currentPage = page;
        if (clearmenu) {
          draft.selectedFood = [];
          draft.showCart = false;
          draft.total = 0;
          draft.tax = 0;
          draft.selectedFoodNumber = 0;
        }
        break;
      }
      case CLEAR_CART: {
        draft.selectedFood = [];
        draft.total = 0;
        draft.tax = 0;
        draft.selectedFoodNumber = 0;
        break;
      }
      case CHANGE_SELECTED_FOOD: {
        draft.selectedFood = action.selectedFood;
        draft.selectedFoodNumber = action.selectedFoodNumber;
        break;
      }
    }
  });

export default Reducer;
