import {
  GET_MENU_STORE,
  GET_MENU_STORE_SUCCEED,
  SET_ERROR_MESSAGE,
  GET_MY_ORDER,
  GET_MY_ORDER_SUCCEED,
  CHECK_ORDER_PRICE,
  CHECK_ORDER_PRICE_SUCCEED,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCEED,
  SWITCH_PAGE,
  CHANGE_SELECTED_FOOD,
  CLEAR_CART,
} from './DigitalMenus.constants';

export const switchPageAction = (page, clearmenu = false) => ({
  type: SWITCH_PAGE,
  page,
  clearmenu,
});

export const changeSelectedFoodAction = (selectedFood, selectedFoodNumber) => ({
  type: CHANGE_SELECTED_FOOD,
  selectedFood,
  selectedFoodNumber,
});

export const submitOrderAction = payload => ({
  type: SUBMIT_ORDER,
  payload,
});

export const submitOrderSucceedAction = payload => ({
  type: SUBMIT_ORDER_SUCCEED,
  payload,
});

export const checkOrderPriceAction = payload => ({
  type: CHECK_ORDER_PRICE,
  payload,
});

export const checkOrderPriceSucceedAction = payload => ({
  type: CHECK_ORDER_PRICE_SUCCEED,
  payload,
});

export const getMyOrderAction = outletCode => ({
  type: GET_MY_ORDER,
  outletCode,
});

export const getMyOrderSucceedAction = payload => ({
  type: GET_MY_ORDER_SUCCEED,
  payload,
});

export const getStoreAndMenuAction = outletCode => ({
  type: GET_MENU_STORE,
  outletCode,
});

export const getStoreAndMenuSucceedAction = payload => ({
  type: GET_MENU_STORE_SUCCEED,
  payload,
});

export const setErrorMessage = error => ({
  type: SET_ERROR_MESSAGE,
  error,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});
