import ApiConstants from '../../../constants/ApiConstants';
import { RequestClientClass } from '../../../utils/requestClient';
import Config from '../../../config';

const requestClient = () => new RequestClientClass(Config.QRAVED_SERVER);

const doCheckQRCode = async payload => {
  const { id } = payload;
  const uri = ApiConstants.qrcode.get(id);
  const response = await requestClient()
    .setUri(uri)
    .doGet();
  return response;
};

export default {
  doCheckQRCode,
};
