import { put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_MENU_STORE,
  CHECK_ORDER_PRICE,
  SUBMIT_ORDER,
  GET_MY_ORDER,
} from './DigitalMenus.constants';
import {
  getStoreAndMenuSucceedAction,
  setErrorMessage,
  getMyOrderSucceedAction,
  checkOrderPriceSucceedAction,
  submitOrderSucceedAction,
} from './DigitalMenus.action';
import DigitalMenusService from '../../service/api/DigitalMenus';

export function* getStoreAndMenu(action) {
  try {
    const { outletCode } = action;
    const response = yield call(
      DigitalMenusService.doGetStoreAndMenu,
      outletCode,
    );
    if (response) {
      yield put(getStoreAndMenuSucceedAction(response));
    } else {
      yield put(setErrorMessage('error'));
    }
  } catch (e) {
    yield put(setErrorMessage('error'));
  }
}

export function* checkOrderPrice(action) {
  try {
    const { payload } = action;
    const response = yield call(DigitalMenusService.doCalculateorder, payload);
    if (response) {
      yield put(checkOrderPriceSucceedAction(response.data));
    } else {
      yield put(setErrorMessage('error'));
    }
  } catch (e) {
    yield put(setErrorMessage('error'));
  }
}

export function* submitOrder(action) {
  try {
    const { payload } = action;
    const response = yield call(DigitalMenusService.doConsultOrder, payload);
    if (response) {
      yield put(submitOrderSucceedAction(response));
    } else {
      yield put(setErrorMessage('error'));
    }
  } catch (e) {
    yield put(setErrorMessage('error'));
  }
}

export function* getMyOrder(action) {
  try {
    const { requestId } = action;
    const response = yield call(DigitalMenusService.doGetMyOrder, requestId);
    if (response) {
      yield put(getMyOrderSucceedAction(response));
    } else {
      yield put(setErrorMessage('error'));
    }
  } catch (e) {
    yield put(setErrorMessage('error'));
  }
}

export default function* signInSaga() {
  yield takeLatest(GET_MENU_STORE, getStoreAndMenu);
  yield takeLatest(CHECK_ORDER_PRICE, checkOrderPrice);
  yield takeLatest(SUBMIT_ORDER, submitOrder);
  yield takeLatest(GET_MY_ORDER, getMyOrder);
}
