import ApiConstants from '../../../constants/ApiConstants';
import { RequestClientClass } from '../../../utils/requestClient';
import Config from '../../../config';

const requestClient = () => new RequestClientClass(Config.DIGITAL_MENU_SERVER);

const doGetStoreAndMenu = async outletCode => {
  const uri = ApiConstants.digitalMenu.getStoreAndMenu(outletCode);
  const response = await requestClient()
    .setUri(uri)
    .doGet();
  return response;
};

const doGetMyOrder = async payload => {
  const { requestID } = payload;
  const uri = ApiConstants.digitalMenu.orderdetails(requestID);
  const response = await requestClient()
    .setUri(uri)
    .doGet();
  return response;
};

const doCalculateorder = async payload => {
  const uri = ApiConstants.digitalMenu.calculateorder;
  const response = await requestClient()
    .setUri(uri)
    .setPayload(payload)
    .doPost();
  return response;
};

const doConsultOrder = async payload => {
  const uri = ApiConstants.digitalMenu.consultOrder;
  const response = await requestClient()
    .setUri(uri)
    .setPayload(payload)
    .doPost();
  return response;
};

export default {
  doGetStoreAndMenu,
  doGetMyOrder,
  doCalculateorder,
  doConsultOrder,
};
