import { createSelector } from 'reselect';

import { REDUCER_KEY } from './ShortUrl.constants';
import { initialState } from './ShortUrl.reducer';

const selectShortUrlState = state => state[REDUCER_KEY] || initialState;

const makeSelectRedirectLink = () =>
  createSelector(
    selectShortUrlState,
    shortUrlState => shortUrlState.redirectLink,
  );
const makeSelectRestaurantId = () =>
  createSelector(
    selectShortUrlState,
    shortUrlState => shortUrlState.restaurantId,
  );
const makeSelectRestaurantName = () =>
  createSelector(
    selectShortUrlState,
    shortUrlState => shortUrlState.RestaurantName,
  );
const makeSelectUTMSource = () =>
  createSelector(selectShortUrlState, shortUrlState => shortUrlState.UTMSource);

const makeSelectSourceTypeName = () =>
  createSelector(
    selectShortUrlState,
    shortUrlState => shortUrlState.sourceTypeName,
  );

const makeSelectLosEvent = () =>
  createSelector(selectShortUrlState, shortUrlState => shortUrlState.losEvent);

const makeSelectErrorMessage = () =>
  createSelector(
    selectShortUrlState,
    shortUrlState => shortUrlState.errorMessage,
  );

const makeSelectBrandId = () =>
  createSelector(selectShortUrlState, shortUrlState => shortUrlState.brand_id);
export {
  makeSelectLosEvent,
  makeSelectRedirectLink,
  makeSelectErrorMessage,
  makeSelectSourceTypeName,
  makeSelectRestaurantId,
  makeSelectRestaurantName,
  makeSelectUTMSource,
  makeSelectBrandId,
};
